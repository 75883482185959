import {Routes, RouterModule} from '@angular/router';
import {
  LandingPageComponent,
} from '../pages';

const MAINMENU_ROUTES: Routes = [
  // full : makes sure the path is absolute path
  {path: '', component: LandingPageComponent},
  {path: '**', redirectTo: '/'}
];
export const CONST_ROUTING = RouterModule.forRoot(MAINMENU_ROUTES);
