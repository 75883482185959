import {Component, ComponentFactoryResolver, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AlertService} from '../../_services';
import {Location} from '@angular/common';
import {LoaderService} from '../loader/loader.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent  implements OnInit {

  isLoading = false;
  isHidden = false;
  isTransparent = true;

  constructor(private router: Router,
              private alertService: AlertService,
              private cfr: ComponentFactoryResolver,
              private location: Location,
              private loaderService: LoaderService) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.isTransparent = offset < 30;
    });


  }

  ngOnInit() {}

  scrollToElement(id): void {
    const el = document.getElementById(id + '-anchor');
    el.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isTransparent = offset < 30;
  }

}
