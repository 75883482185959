import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {map} from 'rxjs/operators';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})


export class FooterComponent {
  hideFooter = true;


  constructor(private router: Router) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.hideFooter = evt.url.indexOf('/discover') === 0;
    });
  }

}
