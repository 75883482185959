import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {AlertService, SendinblueServiceService} from './_services';
import {LoaderService} from './shared/loader/loader.service';
import {AlertComponent} from './_directives';
declare var $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('alertMessage', { read: ViewContainerRef, static: true }) alertMessage: ViewContainerRef;
  userEmail: string;
  isLoading = false;
  constructor(private router: Router,
              private emailSIBService: SendinblueServiceService,
              private route: ActivatedRoute,
              private alertService: AlertService,
              private cfr: ComponentFactoryResolver,
              private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
