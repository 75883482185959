import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }).append('Access-Control-Allow-Headers', 'Content-Type')
    .append('Access-Control-Allow-Methods', 'GET')
    .append('Access-Control-Allow-Origin', '*')
};

const httpOptionsPost = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  })
};

@Injectable()
export class SendinblueServiceService {
  constructor(private http: HttpClient) {
  }

  contactUs(subject: string, name: string, email: string, phone: string, message: string): Promise<any> {
    let topContent = '';

    topContent = `Hi Bamorem,<br/><br/>`;
    topContent += `Your got a message from ${name}<br/><br/>`;
    topContent += `${subject}:<br/><br/>`;
    topContent += `${message} <br/><br/>`;
    topContent += `Contact:<br/>Email: ${email}<br/>Phone: ${phone}`;
    const params = {TOP_CONTENT: topContent};

    const data: any = {
      emailType: 'bamorem',
      sender: {name: 'Bamorem', email: 'yohan_balassiano@hotmail.com'},
      to: [{name: 'Bamorem', email: 'yohan_balassiano@hotmail.com'}],
      params,
      tags: ['Bamorem'],
      // textContent: `From : ${contact.name} | ${contact.email}\n Message : ${contact.description}`,
      subject: `New message! | ${subject}`
    };

    return this.http.post('https://us-central1-crypream.cloudfunctions.net/sendHttpEmail',
      JSON.stringify({data}), httpOptionsPost)
      .toPromise().catch(this.handleError);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead - if we dont do this we wont see the error in red in the console
      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}

