import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule} from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as $ from 'jquery';
import * as bootstrap from 'bootstrap';
import * as moment from 'moment';

import {LoaderComponent} from './shared/loader/loader.component';
import {LoaderService} from './shared/loader/loader.service';
import {MenuComponent} from './shared/menu/menu.component';
import {FooterComponent} from './shared/footer/footer.component';

import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';


import {CONST_ROUTING} from './app.routing';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';



import {
  LandingPageComponent,
} from '../pages';

import {
  AlertService,
  SendinblueServiceService
} from './_services';

import {AlertComponent} from './_directives';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    LoaderComponent,
    LandingPageComponent,
    AlertComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    CONST_ROUTING,
    AngularFireModule.initializeApp(environment.firebase), // Main Angular fire module
    AngularFirestoreModule,  // Firebase database module
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgbModule,
    SlickCarouselModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AlertService,
    LoaderService,
    SendinblueServiceService
  ],
  exports: [
    AppComponent,
    LoaderComponent,
  ],
  bootstrap: [AppComponent],
  entryComponents: [AlertComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
