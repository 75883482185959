<nav class="navbar navbar-expand-md fixed-top " [ngClass]="{'d-none' : isHidden , 'bg-light navbar-light':!isTransparent, 'bg-transparent navbar-dark' : isTransparent}">
  <a class="navbar-brand" [routerLink]="['/']">
    <img height="30px" class="d-none d-md-block" [src]="isTransparent ? 'assets/images/logo/bamorem-name-white.png' : 'assets/images/logo/bamorem-name.png'">
    <img height="30px" class="d-block d-md-none" [src]="isTransparent ? 'assets/images/logo/bamorem-name-white.png' : 'assets/images/logo/bamorem-name.png'">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto text-center">
      <li class="nav-item px-2 " routerLinkActive="active" (click)="scrollToElement('aboutUs')"><a class="nav-link">About us</a></li>
      <li class="nav-item px-2 " routerLinkActive="active" (click)="scrollToElement('expertise')"><a class="nav-link">Expertise</a></li>
      <li class="nav-item px-2 " routerLinkActive="active" (click)="scrollToElement('technologies')"><a class="nav-link">Technologies</a></li>
      <li class="nav-item px-2 " routerLinkActive="active" (click)="scrollToElement('contact')"><a class="nav-link">Contact us</a></li>
    </ul>
  </div>
</nav>
