import {
  Component,
  AfterViewInit,
  ViewContainerRef,
  ViewChild,
  ComponentFactoryResolver, ElementRef
} from '@angular/core';


import {AlertService, SendinblueServiceService} from '../../app/_services';
import {LoaderService} from '../../app/shared/loader/loader.service';
import {AlertComponent} from '../../app/_directives';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],

})
export class LandingPageComponent implements AfterViewInit {
  @ViewChild('alertMessage', {read: ViewContainerRef, static: true}) alertMessage: ViewContainerRef;
  isLoading = false;

  contactInfo: { name?: string, subject?: string, email?: string, phone?: string, message?: string } = {subject: ''};

  constructor(private loaderService: LoaderService,
              public el: ElementRef<HTMLElement>,
              private cfr: ComponentFactoryResolver,
              private alertService: AlertService,
              private emailSIBService: SendinblueServiceService) {
  }

  ngAfterViewInit() {
  }




  contactUs() {
    this.isLoading = true;
    this.loaderService.show();
    this.emailSIBService.contactUs(this.contactInfo.subject, this.contactInfo.name, this.contactInfo.email,
      this.contactInfo.phone, this.contactInfo.message).then(() => {
      this.showAlert('alertMessage');
      this.alertService.success('Thanks for contacting us. we\'ll get back to you as soon as possible');
      this.loaderService.hide();
    }).catch((e) => {
      this.showAlert('alertMessage');
      this.alertService.error(e.message);
      this.loaderService.hide();
    });
  }


  showAlert(target) {
    this[target].clear();
    const factory = this.cfr.resolveComponentFactory(AlertComponent);
    const ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
}
