<header id="header-anchor" #header class="position-relative d-flex">
  <div class="container my-auto">
    <div class="row">
      <div class="col-md-10 py-sm-5 ">
        <h1>Experts in building remote development teams & Secure Software Development</h1>
        <h4 class="font-weight-lighter mb-5">With industry-focused engineers</h4>
        <a class="btn btn-outline-white"
           href="https://api.whatsapp.com/send/?phone=+972585536109&text=Hello.+Please+get+in+touch+with+me">Get in
          touch</a>
      </div>
    </div>
  </div>
</header>

<section id="aboutUs" #aboutUs class="d-flex position-relative bg-gary">
  <span id="aboutUs-anchor" class="position-absolute" style="top:-70px;"></span>
  <div class="container p-5 my-auto text-center">
    <div class="row justify-content-between">
      <div class="col-sm-6">
        <h6 class=" primary-color">ABOUT US</h6>
        <h2 class="pb-3">Regional tech talents for your service</h2>
        <p>
          We are Bamorem – a company specialized in building remote development teams with industry-focused engineers.
          Founded by Yohan Balassiano from France, Bamorem has vast experience in staff augmentation and nearshore
          development.
        </p>
        <a class="btn btn-outline-primary mb-5 mb-sm-0"
           href="https://api.whatsapp.com/send/?phone=+972585536109&text=Hello.+Please+get+in+touch+with+me">Get in
          touch</a>

      </div>
      <div class="col-sm-5">
        <h4><span class="primary-color pr-3">01</span>Request</h4>
        <p>Share the project description and the needed tech stack.</p>
        <hr/>
        <h4><span class="primary-color pr-3">02</span>Choose</h4>
        <p>Receive a selection of the most relevant IT-specialists with deep industry focus to choose from.</p>
        <hr/>
        <h4><span class="primary-color pr-3">03</span>Succeed</h4>
        <p>Concentrate on your project while we take care of all the paperwork, payroll operations and logistics.</p>

      </div>
    </div>
  </div>
</section>

<section id="expertise" #expertise class="d-flex position-relative">
  <span id="expertise-anchor" class="position-absolute" style="top:-70px;"></span>

  <div class="container py-5 my-auto text-center">
    <h2 class="white-color">Our Expertise</h2>
    <p class="white-color">
      In addition to having vast experience in building dedicated development teams in Tel-Aviv, Israel, Bamorem
      succeeds in value-added services like Mobile App development, Big Data analytics and Cloud Engineering.</p>
  </div>
</section>

<section class="d-flex position-relative ">

  <div class="container py-5 my-auto text-center">
    <div class="row  justify-content-center">
      <div class="col-sm-4 py-3 my-2">
        <h4 class="primary-color">
          Enterprise Software Development </h4>
        <p>
          Create complex enterprise software, ensure reliable software integration, modernise your legacy system. </p>
      </div>
      <div class="col-sm-4 py-3 my-2">
        <h4 class="primary-color">
          Mobile App Development </h4>
        <p>
          Create an impactful mobile app that fits your brand and industry within a shorter time frame. </p>
      </div>
      <div class="col-sm-4 py-3 my-2">
        <h4 class="primary-color">
          Dedicated Development Team </h4>
        <p>
          Hire a loyal software development team with niche skills and deep expertise. </p>
      </div>
      <div class="col-sm-4 py-3 my-2">
        <h4 class="primary-color">IT Consulting </h4>
        <p>
          Trust our top minds to eliminate workflow pain points, implement new tech, and consolidate app
          portfolios. </p>
      </div>
      <div class="col-sm-4 py-3 my-2">
        <h4 class="primary-color">
          Cloud Computing </h4>
        <p>
          Use cloud computing solutions to create a scalable, flexible, and connected enterprise environment. </p>
      </div>
      <div class="col-sm-4 py-3 my-2">
        <h4 class="primary-color">
          Artificial Intelligence </h4>
        <p>
          Custom AI-based solutions like machine and deep learning will help you automate any operation. </p>
      </div>
    </div>
  </div>
</section>
<section #technologies id="technologies" class="d-flex position-relative bg-gary">
  <span id="technologies-anchor" class="position-absolute" style="top:-70px;"></span>

  <div class="container py-5 my-auto text-left">
    <h2 class="text-center text-sm-left mb-4 mb-sm-0">Technology stack</h2>

    <div class="row justify-content-center justify-content-sm-between align-items-center">
      <div class="col-12 col-sm-4 col-md-2">
        <h4 class="text-center text-sm-left">Backend</h4>
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img.png">
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_1.png">
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_2.png">
      </div>
    </div>
    <div class="row justify-content-center justify-content-sm-between align-items-center">
      <div class="col-12 col-sm-4 col-md-2">
        <h4 class="text-center text-sm-left">Frontend</h4>
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_3.png">
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_4.png">
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_5.png">
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_6.png">
      </div>
    </div>
    <div class="row justify-content-center justify-content-sm-between align-items-center">
      <div class="col-12 col-sm-4 col-md-2">
        <h4 class="text-center text-sm-left">Mobile</h4>
      </div>

      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_7.png">
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_8.png">
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_9.png">
      </div>
    </div>
    <div class="row justify-content-center justify-content-sm-between align-items-center">
      <div class="col-12 col-sm-4 col-md-2">
        <h4 class="text-center text-sm-left">Other</h4>
      </div>

      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_10.png">
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_11.png">
      </div>
      <div class="col-6 col-sm-4 col-md-2">
        <img height="80px" class="img-fluid" src="assets/images/icons/img_12.png">
      </div>

    </div>
  </div>
</section>

<section #contact id="contact" class="d-flex position-relative ">
  <span id="contact-anchor" class="position-absolute" style="top:-70px;"></span>

  <div class="container py-5 my-auto text-center">
    <div class="row justify-content-center">
      <div class="col-sm-10 col-md-8">
        <h4 class="primary-color">GET IN TOUCH</h4>
        <h2>Drop us a message to see how we can help</h2>
        <h3>Just enter your project details and we will reply within 24 hours</h3>

        <form (ngSubmit)="contactUs()" #contactUsForm="ngForm">

          <div class="row pt-3">
            <div class="col-xs-12 col-sm-6">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-user"></i></span>
                </div>
                <input type="text" class="form-control" id="name" placeholder="Your full name *" name="name"
                       [(ngModel)]="contactInfo.name" required>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                </div>
                <input type="email" class="form-control" placeholder="Your email *" id="email" email="true"
                       [(ngModel)]="contactInfo.email" name="email" required>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-phone-alt"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Your phone *" [(ngModel)]="contactInfo.phone"
                       id="phone" name="phone" required>
              </div>
            </div>

          </div>
          <div class="">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" style="align-items: flex-start;padding-top: 10px"><i
                  class="fas fa-pen"></i></span>
              </div>
              <textarea class="form-control" [(ngModel)]="contactInfo.message" id="message" name="message"
                        placeholder="Your message *" rows="6" aria-label="With textarea" required></textarea>
            </div>
          </div>
          <ng-template #alertMessage></ng-template>

          <div class="mt-4">
            <button type="submit" class="btn btn-primary" [disabled]="!contactUsForm.form.valid || isLoading">ENVOYER
            </button>
          </div>

        </form>


      </div>
    </div>
  </div>
</section>
